

export class Admin {
  public name: string;
  public average: Map<string, string>;
  public celon_logo: string;
  public estimated_values: Map<number, string>;
  public head_table_color: string;
  public head_table_letter_color: string;
  public page_id: Map<string, string>;
  public profit_margin: Map<string, string>;
  public role: string;
  public uid: string;
  public dataFacebook: any;
  public dataTikTok: any;

  constructor(obj: IAdmin) {
    this.name = obj && obj.name || '';
    this.average = obj && obj.average || new Map();
    this.celon_logo = obj && obj.celon_logo || '';
    this.estimated_values = obj && obj.estimated_values || new Map();
    this.head_table_color = obj && obj.head_table_color || '';
    this.head_table_letter_color = obj && obj.head_table_letter_color || '';
    this.page_id = obj && obj.page_id || new Map();
    this.profit_margin = obj && obj.profit_margin || new Map();
    this.role = obj && obj.role || '';
    this.uid = obj && obj.uid || '';
    this.dataFacebook = obj && obj.dataFacebook || [];
    this.dataTikTok = obj && obj.dataTikTok || [];
  }
}

export interface IAdmin {
  name: string;
  average: Map<string, string>;
  celon_logo: string;
  estimated_values: Map<number, string>;
  head_table_color: string;
  head_table_letter_color: string;
  page_id: Map<string, string>;
  profit_margin: Map<string, string>;
  role: string;
  uid: string;
  dataFacebook: any[];
  dataTikTok: any[];
}
