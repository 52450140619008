import * as actions from './graphic-mixed-daily.actions';
import { GraphicMixedDaily } from './graphic-mixed-daily.model';

export interface GraphicsMixedDailyState {
  graphics: GraphicMixedDaily
}

const initialState: GraphicsMixedDailyState = {
  graphics: null
}

export function graphicsMixedDailyReducer(state = initialState, action: actions.actions): GraphicsMixedDailyState {
  switch (action.type) {
    case actions.SET_GRAPHICS_MIXED_DAILY:
      return {
        ...state,
        graphics: action.graphics
      };

    case actions.UNSET_GRAPHICS_MIXED_DAILY:
      return {
        graphics: null
      };

    default:
      return state;
  }
}
