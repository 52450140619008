

export class User {
  public name: string;
  public account_list: string[];
  public account_name: string[];
  public average: Map<string,string>;
  public bigquery_table?: string;
  public bigquery_tables?: string[];
  public celon_logo: string;
  public client_logo: string;
  public estimated_value: number;
  public graphics: string[];
  public head_table_color: string;
  public head_table_letter_color: string;
  public hidden_campaign: string[];
  public profit_margin: Map<string,string>;
  public role: string;
  public tiktok_tables_map: Map<string,string>;
  public uid?: string;
  public bigquery_tables_tiktok?: string[];

  constructor(obj: IUser){
    this.name = obj && obj.name || '';
    this.account_list = obj && obj.account_list || [];
    this.account_name = obj && obj.account_name || [];
    this.average = obj && obj.average || new Map();
    this.celon_logo = obj && obj.celon_logo || '';
    this.client_logo = obj && obj.client_logo || '';
    this.estimated_value = obj && obj.estimated_value || 0;
    this.graphics = obj && obj.graphics || [];
    this.head_table_color = obj && obj.head_table_color || '';
    this.head_table_letter_color = obj && obj.head_table_letter_color || '';
    this.hidden_campaign = obj && obj.hidden_campaign || [];
    this.profit_margin = obj && obj.profit_margin || new Map();
    this.role = obj && obj.role || '';
    this.tiktok_tables_map = obj && obj.tiktok_tables_map || new Map();
    this.bigquery_table = obj && obj.bigquery_table || '';
    this.bigquery_tables = obj && obj.bigquery_tables || [];
    this.uid = obj && obj.uid || '';
    this.bigquery_tables_tiktok = obj && obj.bigquery_tables_tiktok || [];
  }
}

export interface IUser{
  name: string;
  account_list: string[];
  average: Map<string,string>;
  celon_logo: string;
  client_logo: string;
  estimated_value: number;
  graphics: string[];
  head_table_color: string;
  head_table_letter_color: string;
  hidden_campaign: string[];
  profit_margin: Map<string,string>;
  role: string;
  tiktok_tables_map: Map<string,string>;
  account_name: string[];
  uid?: string;
  bigquery_table?: string;
  bigquery_tables?: string[];
  bigquery_tables_tiktok?: string[];
}
