import * as actions from './data.actions';
import { Data } from './data.model';

export interface DataState {
  data: Data
}

const initialState: DataState = {
  data: null
}

export function dataReducer(state = initialState, action: actions.actions): DataState {
  switch (action.type) {
    case actions.SET_DATA:
      return {
        ...state,
        data: action.data
      };

    case actions.UNSET_DATA:
      return {
        data: null
      };

    default:
      return state;
  }
}
