import * as actions from './graphic-image-as-category.actions';
import { GraphicImageAsCategory } from './graphic-image-as-category.model';

export interface GraphicsImageAsCatgeoryState {
  graphics: GraphicImageAsCategory
}

const initialState: GraphicsImageAsCatgeoryState = {
  graphics: null
}

export function graphicsImageAsCategoryReducer(state = initialState, action: actions.actions): GraphicsImageAsCatgeoryState {
  switch (action.type) {
    case actions.SET_GRAPHICS_IMAGE_CATEGORY:
      return {
        ...state,
        graphics: action.graphics
      };

    case actions.UNSET_GRAPHICS_IMAGE_CATEGORY:
      return {
        graphics: null
      };

    default:
      return state;
  }
}
