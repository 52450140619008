import { Action } from "@ngrx/store";
import { SpeedBarChartCompany } from "./speed-bacrchart-company.model";


export const SET_SPEED_BAR_CHART_COMPANY = '[SpeedBarChartCompany] Set SpeedBarChartCompany';
export const UNSET_SPEED_BAR_CHART_COMPANY = '[SpeedBarChartCompany] Unset SpeedBarChartCompany';
export const UPDATE_SPEED_BAR_CHART_COMPANY = '[SpeedBarChartCompany] Update SpeedBarChartCompany';

export class SetSpeedBarChartCompanyAction implements Action {
  readonly type = SET_SPEED_BAR_CHART_COMPANY;

  constructor(public speedBarChartCompany: SpeedBarChartCompany){}
}

export class UnsetSpeedBarChartCompanyAction implements Action { 
  readonly type = UNSET_SPEED_BAR_CHART_COMPANY;
}

export class UpdateSpeedBarChartCompanyAction implements Action {
  readonly type = UPDATE_SPEED_BAR_CHART_COMPANY;

  constructor(public speedBarChartCompany: SpeedBarChartCompany){}
}

export type actions = SetSpeedBarChartCompanyAction | UnsetSpeedBarChartCompanyAction | UpdateSpeedBarChartCompanyAction;
