import { Action } from "@ngrx/store";
import { Admin } from "./admin.model";


export const SET_USER_ADMIN = '[AuthAdmin] Set User';
export const UNSET_USER_ADMIN = '[AuthAdmin] Unset User';

export class SetUserAdminAction implements Action {
  readonly type = SET_USER_ADMIN;

  constructor(public user: Admin){}
}

export class UnsetUserAdminAction implements Action {
  readonly type = UNSET_USER_ADMIN;
}

export type actions = SetUserAdminAction | UnsetUserAdminAction;
