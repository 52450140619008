import * as actions from './auth.actions';
import { User } from "./user.model";

export interface AuthState {
  user: User
}

const initialState: AuthState = {
  user: null
}

export function authReducer(state = initialState, action: actions.actions): AuthState {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        user: action.user
      };

    case actions.UNSET_USER:
      return {
        user: null
      };

    default:
      return state;
  }
}
