import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'src/app/components/pages/user.model';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { SetUserAction, UnsetUserAction } from 'src/app/components/pages/auth.actions';
import { Subscription } from 'rxjs';
import { Data } from 'src/app/components/pages/data.model';
import { SetDataAction, UnsetDataAction } from 'src/app/components/pages/data.actions';
import { UnsetGraphicsClientAction } from 'src/app/components/graphics/client-graphics/graphics-client.actions';
import { SetDataCompanyAction, UnsetDataCompanyAction } from 'src/app/components/pages/data-company.actions';
import { DataCompany } from 'src/app/components/pages/data-company.model';
import { SetUserAdminAction, UnsetUserAdminAction } from 'src/app/components/pages/auth-admin.actions';
import { Admin } from 'src/app/components/pages/admin.model';
import { UnsetGraphicsImageAsCategoryAction } from 'src/app/components/graphics/graphic-image-as-category/graphic-image-as-category.actions';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  // Subscripciones a objetos
  userSubscription: Subscription = new Subscription();
  accessTokenSuscription: Subscription = new Subscription();
  tablesSuscription: Subscription = new Subscription();

  dataCompany;
  data = new Data();
  usuario;
  accessToken;

  constructor(private db: AngularFirestore, private auth: AngularFireAuth, private store: Store<AppState>) { }

  initAuthListener() {
    this.auth.authState.subscribe((firebaseUser) => {
      if (firebaseUser) {
        this.userSubscription = this.db.doc(`${firebaseUser.uid}/User`).valueChanges()
          .subscribe((user: any) => {
            if (user.role == 'Client') {
              let newUser = new User(user);
              newUser.uid = firebaseUser.uid;
              this.store.dispatch(new SetUserAction(newUser));
              this.usuario = newUser;
            } else if (user.role == 'Admin') {
              let newUser = new Admin(user);
              this.tablesSuscription = this.db.collection(`${firebaseUser.uid}/User/facebook`).valueChanges()
              .subscribe((data: any) => {
                newUser = {...newUser, dataFacebook: data};
              });
              this.tablesSuscription = this.db.collection(`${firebaseUser.uid}/User/tiktok`).valueChanges()
              .subscribe((data: any) => {
                newUser = {...newUser, dataTikTok: data};
              });
              this.usuario = newUser;
              setTimeout(()=>{

                this.store.dispatch(new SetUserAdminAction(newUser));
              }, 750)
            }
          });
        let dbToken = 'Ba1we7dr2Rd3DFA8716daq21';

        this.accessTokenSuscription = this.db.doc(`${dbToken}/token`).valueChanges()
          .subscribe((data: any) => {
            const newData = new Data({...this.data});
            newData.accessToken = data.access_token;
            this.store.dispatch(new SetDataAction(newData));
          });

        this.dataCompany = new DataCompany();
        this.store.dispatch(new SetDataCompanyAction(this.dataCompany))
      } else {
        this.usuario = null;
        this.accessToken = null;
        this.userSubscription.unsubscribe();
        this.accessTokenSuscription.unsubscribe();
      }
    })
  }

  loginUser(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }


  getAccessToken() {
    let token = 'Ba1we7dr2Rd3DFA8716daq21';
    return this.db.firestore.collection(token).get().then((data: any) => {
      let access_token = data.docs.map(e => {
        return e.data().access_token;
      });
      return access_token;
    });
  }

  getDataUser() {
    return this.auth.onAuthStateChanged(function (user) {
      if (user) {
        return user;
      }
    })
  }


  getUserId() {
    return this.auth.currentUser.then((data: any) => {
      return data.uid;
    });
  }

  getAllDataUser(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let account_ids = data.docs.map(e => {
        return e.data();
      });
      return account_ids[0];
    });
  }

  getAccountIds(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let account_ids = data.docs.map(e => {
        return e.data().account_list;
      });
      return account_ids[0];
    });
  }

  getAccountNames(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let account_names = data.docs.map(e => {
        return e.data().account_names;
      });
      return account_names[0];
    });
  }

  getBigQueryTables(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let bigquery_tables = data.docs.map(e => {
        return e.data().bigquery_tables;
      });
      return bigquery_tables[0];
    });
  }

  getTikTokTablesMap(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let tiktok_tables_map = data.docs.map(e => {
        return e.data().tiktok_tables_map;
      });
      return tiktok_tables_map[0];
    });
  }

  getBigQueryTable(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let bigquery_table = data.docs.map(e => {
        return e.data().bigquery_table;
      });
      return bigquery_table[0];
    });
  }

  getProfitMargin(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let profit_margin = data.docs.map(e => {
        return e.data().profit_margin;
      });
      return profit_margin[0];
    });
  }

  getPagesKeys(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let account_page_list_keys = data.docs.map(e => {
        return e.data().account_page_list_keys;
      });
      return account_page_list_keys[0];
    });
  }

  getPageList(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let page_list = data.docs.map(e => {
        return e.data().account_pages_list;
      });
      return page_list[0];
    });
  }

  getPagesId(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let page_id = data.docs.map(e => {
        return e.data().page_id;
      });
      return page_id[0];
    });
  }



  getEstimatedValues(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let estimated_values = data.docs.map(e => {
        return e.data().estimated_values;
      });
      return estimated_values[0];
    });
  }

  getAverage(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let average = data.docs.map(e => {
        return e.data().average;
      });
      return average[0];
    });
  }

  getHiddenCampaigns(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let hidden_campaigns = data.docs.map(e => {
        return e.data().hidden_campaigns;
      });
      return hidden_campaigns[0];
    });
  }

  getAccountRole(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let role = data.docs.map(e => {
        return e.data().role;
      });
      return role;
    });
  }

  getEstimatedValue(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let estimated_value = data.docs.map(e => {
        return e.data().estimated_value;
      });
      return estimated_value;
    });
  }

  getAccountName(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let name = data.docs.map(e => {
        return e.data().name;
      });
      return name;
    });
  }

  getLogoClient(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let image = data.docs.map(e => {
        return e.data().client_logo;
      });
      return image;
    });
  }

  getLogoCelon(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let image = data.docs.map(e => {
        return e.data().celon_logo;
      });
      return image;
    });
  }

  getHeadTableColor(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let head_table_color = data.docs.map(e => {
        return e.data().head_table_color;
      });
      return head_table_color;
    });
  }

  getHeadTableLetterColor(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let head_table_letter_color = data.docs.map(e => {
        return e.data().head_table_letter_color;
      });
      return head_table_letter_color;
    });
  }

  getGraphics(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let graphics = data.docs.map(e => {
        return e.data().graphics;
      });
      return graphics[0];
    });
  }

  setAccessToken(user_id, access_token_item) {
    this.db.firestore.collection(user_id).doc('User').update({ access_token: access_token_item });
  }

  setCampaignDraft(user_id, draft_name, campaign) {
    this.db.firestore.collection(user_id).get().then((data: any) => {
      let campaigns_draft = data.docs.map(e => {
        return e.data().campaigns_draft;
      })
      campaigns_draft[0][draft_name] = campaign;
      this.db.firestore.collection(user_id).doc('User').update({ campaigns_draft: campaigns_draft[0] });
    });
  }

  setAdsetDraft(user_id, draft_name, adset) {
    this.db.firestore.collection(user_id).get().then((data: any) => {
      let adset_draft = data.docs.map(e => {
        return e.data().adset_draft;
      })
      adset_draft[0][draft_name] = adset;
      this.db.firestore.collection(user_id).doc('User').update({ adset_draft: adset_draft[0] });
    });
  }

  setAdDraft(user_id, draft_name, ad) {
    this.db.firestore.collection(user_id).get().then((data: any) => {
      let ad_draft = data.docs.map(e => {
        return e.data().ad_draft;
      })
      ad_draft[0][draft_name] = ad;
      this.db.firestore.collection(user_id).doc('User').update({ ad_draft: ad_draft[0] });
    });
  }

  getCampaignDraft(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let campaigns_draft = data.docs.map(e => {
        return e.data().campaigns_draft;
      });
      return campaigns_draft[0];
    });
  }

  getAdsetDraft(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let adset_draft = data.docs.map(e => {
        return e.data().adset_draft;
      });
      return adset_draft[0];
    });
  }

  getAdDraft(user_id) {
    return this.db.firestore.collection(user_id).get().then((data: any) => {
      let ad_draft = data.docs.map(e => {
        return e.data().ad_draft;
      });
      return ad_draft[0];
    });
  }

  logOut() {
    this.store.dispatch(new UnsetUserAction());
    this.store.dispatch(new UnsetDataAction());
    this.store.dispatch(new UnsetGraphicsClientAction());
    this.store.dispatch(new UnsetUserAdminAction());
    this.store.dispatch(new UnsetGraphicsImageAsCategoryAction());
    this.store.dispatch(new UnsetDataCompanyAction());
    this.userSubscription.unsubscribe();
    return this.auth.signOut();
  }
}
