import { Action } from "@ngrx/store";
import { GraphicMixedDaily } from "./graphic-mixed-daily.model";


export const SET_GRAPHICS_MIXED_DAILY = '[Graphics Mixed Daily] Set Graphics Mixed Daily';
export const UNSET_GRAPHICS_MIXED_DAILY = '[Graphics Mixed Daily] Unset Graphics Mixed Daily';

export class SetGraphicsMixedDaily implements Action {
  readonly type = SET_GRAPHICS_MIXED_DAILY;

  constructor(public graphics: GraphicMixedDaily){}
}

export class UnsetGraphicsMixedDaily implements Action {
  readonly type = UNSET_GRAPHICS_MIXED_DAILY;
}

export class UpdateGraphicsMixedDaily implements Action {
  readonly type = SET_GRAPHICS_MIXED_DAILY;

  constructor(public graphics: GraphicMixedDaily){}
}

export type actions = SetGraphicsMixedDaily | UnsetGraphicsMixedDaily;
