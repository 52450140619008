import { Action } from "@ngrx/store";
import { DataCompany } from "./data-company.model";


export const SET_DATA_COMPANY = '[DataCompany] Set DataCompany';
export const UNSET_DATA_COMPANY = '[DataCompany] Unset DataCompany';

export class SetDataCompanyAction implements Action {
  readonly type = SET_DATA_COMPANY;

  constructor(public dataCompany: DataCompany){}
}

export class UnsetDataCompanyAction implements Action {
  readonly type = UNSET_DATA_COMPANY;
}

export type actions = SetDataCompanyAction | UnsetDataCompanyAction ;
