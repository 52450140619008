import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { AuthenticationGuard } from './guards/authentication.guard';
import { CampaignGuard } from './guards/campaign.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: 'admin-campaigns', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthenticationGuard] },
  { path: 'admin-subscriptions', loadChildren: () => import('./components/pages/admin-subscriptions/admin-subscriptions.module').then(m => m.AdminSubscriptionsModule), canActivate: [AuthenticationGuard] },
  { path: 'admin-overview', loadChildren: () => import('./components/pages/admin-panel/admin-panel.module').then(m => m.AdminPanelModule), canActivate: [AuthenticationGuard] },
  { path: 'admin-comparator', loadChildren: () => import('./components/pages/admin-comparator/admin-comparator.module').then(m => m.AdminComparatorModule), canActivate: [AuthenticationGuard] },
  { path: 'campaigns', loadChildren: () => import('./components/pages/client-home/client-home.module').then(m => m.ClientHomeModule), canActivate: [AuthenticationGuard] },
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard] },
  { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
  { path: 'client', loadChildren: () => import('./components/pages/client-admin/client-admin.module').then(m => m.ClientAdminModule), canActivate: [CampaignGuard] },
  { path: 'dashboard', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [CampaignGuard] },
  { path: 'overview', loadChildren: () => import('./components/pages/new-tab/new-tab.module').then(m => m.NewTabModule), canActivate: [CampaignGuard] },
  { path: 'campaigns-management', loadChildren: () => import('./components/campaign-admin-manage/campaign-admin-manage.module').then(m => m.CampaignAdminManageModule), canActivate: [CampaignGuard] },
  { path: 'test-space', loadChildren: () => import('./components/pages/test-space/test-space.module').then(m => m.TestSpaceModule), canActivate: [AuthenticationGuard] },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
