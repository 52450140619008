import { Component, OnInit } from '@angular/core';
import { FirebaseService } from './services/firebase/firebase.service';
import * as am5 from "@amcharts/amcharts5";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public authService: FirebaseService) {
    am5.addLicense("AM5C354999637");
  }

  ngOnInit(): void {
    this.authService.initAuthListener();
  }
}

