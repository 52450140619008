import * as actions from './bar-chart-platform.actions';
import { BarChartPlatform } from "./bar-chart-platform.model";

export interface BarChartPlatformState {
  barChartPlatform: BarChartPlatform 
}

const initialState: BarChartPlatformState = {
  barChartPlatform: null
}

export function BarChartPlatformReducer(state = initialState, action: actions.actions): BarChartPlatformState {
  switch (action.type) {
    case actions.SET_BAR_CHART_PLATFORM:
      return {
        ...state,
        barChartPlatform: action.barChartPlatform
      };

    case actions.UNSET_BAR_CHART_PLATFORM:
      return {
        barChartPlatform: null
      };

    default:
      return state;
  }
}
