import * as actions from './speed-bacrchart-company.actions';
import { SpeedBarChartCompany } from "./speed-bacrchart-company.model";

export interface SpeedBarChartCompanyState {
  speedBarChartCompany: SpeedBarChartCompany 
}

const initialState: SpeedBarChartCompanyState = {
  speedBarChartCompany: null
}

export function speedBarChartCompanyReducer(state = initialState, action: actions.actions): SpeedBarChartCompanyState {
  switch (action.type) {
    case actions.SET_SPEED_BAR_CHART_COMPANY:
      return {
        ...state,
        speedBarChartCompany: action.speedBarChartCompany
      };

    case actions.UNSET_SPEED_BAR_CHART_COMPANY:
      return {
        speedBarChartCompany: null
      };

    default:
      return state;
  }
}
