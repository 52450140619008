import { Action } from "@ngrx/store";
import { Data } from "./data.model";


export const SET_DATA = '[Data] Set Data';
export const UNSET_DATA = '[Data] Unset Data';

export class SetDataAction implements Action {
  readonly type = SET_DATA;

  constructor(public data: Data){}
}

export class UnsetDataAction implements Action {
  readonly type = UNSET_DATA;
}

export type actions = SetDataAction | UnsetDataAction ;
