import * as actions from './data-company.actions';
import { DataCompany } from './data-company.model';

export interface DataCompanyState {
  dataCompany: DataCompany
}

const initialState: DataCompanyState = {
  dataCompany: null
}

export function dataCompanyReducer(state = initialState, action: actions.actions): DataCompanyState {
  switch (action.type) {
    case actions.SET_DATA_COMPANY:
      return {
        ...state,
        dataCompany: action.dataCompany
      };

    case actions.UNSET_DATA_COMPANY:
      return {
        dataCompany: null
      };

    default:
      return state;
  }
}
