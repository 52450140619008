import { Action } from "@ngrx/store";
import { BarChartCompany } from "./bar-chart-company.model";


export const SET_BAR_CHART_COMPANY = '[BarChartCompany] Set BarChartCompany';
export const UNSET_BAR_CHART_COMPANY = '[BarChartCompany] Unset BarChartCompany';
export const UPDATE_BAR_CHART_COMPANY = '[BarChartCompany] Update BarChartCompany';

export class SetBarChartCompanyAction implements Action {
  readonly type = SET_BAR_CHART_COMPANY;

  constructor(public barChartCompany: BarChartCompany){}
}

export class UnsetBarChartCompanyAction implements Action { 
  readonly type = UNSET_BAR_CHART_COMPANY;
}

export class UpdateBarChartCompanyAction implements Action {
  readonly type = UPDATE_BAR_CHART_COMPANY;

  constructor(public barChartCompany: BarChartCompany){}
}

export type actions = SetBarChartCompanyAction | UnsetBarChartCompanyAction | UpdateBarChartCompanyAction;
