import * as actions from './bar-chart-company.actions';
import { BarChartCompany } from "./bar-chart-company.model";

export interface BarChartCompanyState {
  barChartCompany: BarChartCompany 
}

const initialState: BarChartCompanyState = {
  barChartCompany: null
}

export function barChartCompanyReducer(state = initialState, action: actions.actions): BarChartCompanyState {
  switch (action.type) {
    case actions.SET_BAR_CHART_COMPANY:
      return {
        ...state,
        barChartCompany: action.barChartCompany
      };

    case actions.UNSET_BAR_CHART_COMPANY:
      return {
        barChartCompany: null
      };

    default:
      return state;
  }
}
 