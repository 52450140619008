import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { FirebaseService } from '../services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private fbAuth: AngularFireAuth, private route: Router, private firestore_service: FirebaseService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.fbAuth.authState.pipe(map(auth=>{
      if(!auth){
        this.route.navigate(['/login']);
        return false;
      } else {
        this.firestore_service.getDataUser().then(() => {
          this.firestore_service.getUserId().then((user_id: any) => {
            this.firestore_service.getAccountRole(user_id).then(role => {
              if(role[0] == 'Admin') {
                if(this.route.url == '/admin-campaigns' || this.route.url == '/admin-subscriptions'){
                  this.route.navigate([this.route.url]);
                }
              } else if(role[0] == 'Client') {
                this.firestore_service.getAccountName(user_id).then(name => {
                  if(name[0] == 'demo'){
                    this.route.navigate(['/overview']);
                  } else {
                    this.route.navigate(['/campaigns']);
                  }
                })
              } else if(role[0] == 'client_money') {
                this.route.navigate(['/test-space']);
              }
            })
          })
        })
        return true;
      }
    }));
  }

}
