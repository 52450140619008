export class DataCompany {

  //public presupuesto_ficticio_tk: number[];
  //public presupuesto_ficticio: number[];
  public presupuesto :any[];
  public inversion_ficticio: number[];
  public rebate_ficticio_names: string[];
  public rebate_ficticio_inversion: number[];
  public rebate_ficticio_porcentaje :number[];
  public rebate_inversion_total : number[];
  public rebate_aplicado: string[];
  public header_table_2: string[];
  public header_table: string[];
  public total_datas:number [];
  public total_datas_tk: number[];
  public gasto_total: number;
  public gasto_total_tk: number;
  public presupuesto_total: number;
  public conversion_total: number;
  public grafica_gasto:number;
  public since :string;
  public until :string;
  public date :string;
  public breakdown : string;
  public isBreakdownChanged :boolean;
  public total_marca_desplegable :any [];
  public isEdit : boolean;
  public isOverview: boolean;
  public isCampaing:boolean;




  constructor(obj?: IDataCompany) {

      //this.presupuesto_ficticio_tk = obj && obj.presupuesto_ficticio_tk || [25000, 13800, 156000,96000, 14000, 15600,8000, 16500, 156000,2700, 14000, 15600,29000, 19700, 15600,28000, 15700, 15600];
      //this.presupuesto_ficticio = obj && obj.presupuesto_ficticio || [500000, 4000000, 560000,500000, 400000, 560000,500000, 400000, 560000,500000, 400000, 560000,500000, 400000, 560000,500000, 400000, 560000];
      this.presupuesto = obj && obj.presupuesto || [];
      this.rebate_ficticio_names = obj && obj.rebate_ficticio_names || ['Meta', 'Meta', 'Tik Tok'];
      this.rebate_ficticio_inversion = obj && obj.rebate_ficticio_inversion || [100000, 3000000, 50000];
      this.rebate_ficticio_porcentaje = obj && obj.rebate_ficticio_porcentaje || [10, 12.5, 10];
      this.rebate_inversion_total = obj && obj.rebate_inversion_total || [0, 0, 0];
      this.rebate_aplicado = obj && obj.rebate_aplicado || ['Si', 'No', 'No'];
      this.header_table_2 = obj && obj.header_table_2 || ['Plataforma', 'marca', 'Gasto', 'Presupuesto', '% gasto'];
      this.header_table = obj && obj.header_table || ['Grupo', 'Marca', 'Plataforma', 'Gasto', 'Conversiones', 'Valor', 'Presupuesto', '% Gasto'];
      this.total_datas = obj && obj.total_datas || [];
      this.total_datas_tk = obj && obj.total_datas_tk || [];
      this.gasto_total= obj && obj.gasto_total || 0;
      this.gasto_total_tk = obj && obj.gasto_total_tk || 0;
      this.presupuesto_total = obj && obj.presupuesto_total || 0;
      this.conversion_total =obj && obj.conversion_total || 0;
      this.grafica_gasto = obj && obj.grafica_gasto || 0;
      this.since = obj && obj.since || '';
      this.until = obj && obj.until || '';
      this.date = obj && obj.date || '';
      this.breakdown = obj && obj.breakdown || '';
      this.isBreakdownChanged = obj && obj.isBreakdownChanged || false;
      this.total_marca_desplegable = obj && obj.total_marca_desplegable || [];
      this.isEdit = obj && obj.isEdit || false;
      this.isOverview = obj && obj.isOverview || false; 
      this.isCampaing = obj && obj.isCampaing || true; 
      


  }
}

export interface IDataCompany {

  //presupuesto_ficticio_tk: number[];
  //presupuesto_ficticio: number[];
  presupuesto: any[];
  inversion_ficticio : number[];
  rebate_ficticio_names : string[];
  rebate_ficticio_inversion : number[];
  rebate_ficticio_porcentaje : number[];
  rebate_inversion_total :number[];
  rebate_aplicado : string[];
  header_table_2: string[];
  header_table: string[];
  total_datas:number [];
  total_datas_tk: number[];
  gasto_total: number;
  gasto_total_tk: number;
  presupuesto_total: number;
  conversion_total: number;
  grafica_gasto:number;
  since :string;
  until :string;
  date :string;
  breakdown : string;
  isBreakdownChanged : boolean;
  total_marca_desplegable:any[];
  isEdit:boolean;
  isOverview : boolean;
  isCampaing : boolean; 




}