import { ActionReducerMap } from "@ngrx/store";
import * as graphicsClientState from "./components/graphics/client-graphics/graphics-client.reducer";
import * as graphicsCompanyState from "./components/graphics/graphics-company/graphics-company.reducer";
import * as graphicsMixedDailyState from "./components/graphics/graphic-mixed-daily/graphic-mixed-daily.reducer";
import * as graphicsAsCategoryState from "./components/graphics/graphic-image-as-category/graphic-image-as-category.reducer";
import * as authState from "./components/pages/auth.reducer";
import * as dataState from "./components/pages/data.reducer";
import * as dataCompanyState from "./components/pages/data-company.reducer";
import * as authAdminState from "./components/pages/auth-admin.reducer";
import { GraphicsMixedDailyState } from "./components/graphics/graphic-mixed-daily/graphic-mixed-daily.reducer";
import * as speedBarChartCompanyState from "./components/graphics/speed-barchart-company/speed-bacrchart-company.reducer";
import * as barChartPlatformState from "./components/graphics/bar-chart-platform-company/bar-chart-platform.reducer";
import * as pieChartCompanyState from "./components/graphics/pie-chart-company/pie-chart-company.reducer";
import * as barChartCompanyState from "./components/graphics/bar-chart-company/bar-chart-company.reducer";


export interface AppState{
  auth: authState.AuthState;
  authAdmin: authAdminState.AuthAdminState;
  data: dataState.DataState;
  dataCompany: dataCompanyState.DataCompanyState;
  graphicsClient: graphicsClientState.GraphicsClientState;
  graphicsCompany: graphicsCompanyState.GraphicsCompanyState;
  graphicsImageAsCategory: graphicsAsCategoryState.GraphicsImageAsCatgeoryState;
  graphicsMixedDaily: graphicsMixedDailyState.GraphicsMixedDailyState;
  speedBarChartCompany: speedBarChartCompanyState.SpeedBarChartCompanyState;
  barChartPlatform: barChartPlatformState.BarChartPlatformState;
  pieChartCompany: pieChartCompanyState.PieChartCompanyState;
  barChartCompany: barChartCompanyState.BarChartCompanyState;
}

export const appReducers: ActionReducerMap<AppState> = {
  auth: authState.authReducer,
  data: dataState.dataReducer,
  dataCompany: dataCompanyState.dataCompanyReducer,
  graphicsClient: graphicsClientState.graphicsClientReducer,
  graphicsCompany: graphicsCompanyState.graphicsCompanyReducer,
  authAdmin: authAdminState.authAdminReducer,
  graphicsImageAsCategory: graphicsAsCategoryState.graphicsImageAsCategoryReducer,
  graphicsMixedDaily: graphicsMixedDailyState.graphicsMixedDailyReducer,
  speedBarChartCompany: speedBarChartCompanyState.speedBarChartCompanyReducer,
  barChartPlatform: barChartPlatformState.BarChartPlatformReducer,
  pieChartCompany: pieChartCompanyState.pieChartCompanyReducer,
  barChartCompany: barChartCompanyState.barChartCompanyReducer,
}
