import { Action } from "@ngrx/store";
import { GraphicsCompany } from "./graphics-company.model";


export const SET_GRAPHICS_COMPANY = '[GraphicsCompany] Set GraphicsCompany';
export const UNSET_GRAPHICS_COMPANY = '[GraphicsCompany] Unset GraphicsCompany';
export const UPDATE_GRAPHICS_COMPANY = '[GraphicsCompany] Update GraphicsCompany';

export class SetGraphicsCompanyAction implements Action {
  readonly type = SET_GRAPHICS_COMPANY;

  constructor(public graphicsCompany: GraphicsCompany){}
}

export class UnsetGraphicsCompanyAction implements Action {
  readonly type = UNSET_GRAPHICS_COMPANY;
}

export class UpdateGraphicsCompanyAction implements Action {
  readonly type = UPDATE_GRAPHICS_COMPANY;

  constructor(public graphicsCompany: GraphicsCompany){}
}

export type actions = SetGraphicsCompanyAction | UnsetGraphicsCompanyAction | UpdateGraphicsCompanyAction;
