import { Action } from "@ngrx/store";
import { GraphicsClient } from "./graphics-client.model";


export const SET_GRAPHICS = '[Graphics] Set Graphics';
export const UNSET_GRAPHICS = '[Graphics] Unset Graphics';
export const UPDATE_GRAPHICS = '[Graphics] Update Graphics';

export class SetGraphicsClientAction implements Action {
  readonly type = SET_GRAPHICS;

  constructor(public graphics: GraphicsClient){}
}

export class UnsetGraphicsClientAction implements Action {
  readonly type = UNSET_GRAPHICS;
}

export class UpdateGraphicsClientAction implements Action {
  readonly type = UPDATE_GRAPHICS;

  constructor(public graphics: GraphicsClient){}
}

export type actions = SetGraphicsClientAction | UnsetGraphicsClientAction | UpdateGraphicsClientAction;
