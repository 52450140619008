import { Admin } from './admin.model';
import * as actions from './auth-admin.actions';

export interface AuthAdminState {
  user: Admin
}

const initialState: AuthAdminState = {
  user: null
}

export function authAdminReducer(state = initialState, action: actions.actions): AuthAdminState {
  switch (action.type) {
    case actions.SET_USER_ADMIN:
      return {
        ...state,
        user: action.user
      };

    case actions.UNSET_USER_ADMIN:
      return {
        user: null
      };

    default:
      return state;
  }
}
