import * as actions from './pie-chart-company.actions';
import { PieChartCompany } from "./pie-chart-company.model";

export interface PieChartCompanyState {
  pieChartCompany: PieChartCompany 
}

const initialState: PieChartCompanyState = {
  pieChartCompany: null
}

export function pieChartCompanyReducer(state = initialState, action: actions.actions): PieChartCompanyState {
  switch (action.type) {
    case actions.SET_PIE_CHART_COMPANY:
      return {
        ...state,
        pieChartCompany: action.pieChartCompany
      };

    case actions.UNSET_PIE_CHART_COMPANY:
      return {
        pieChartCompany: null
      };

    default:
      return state;
  }
}
