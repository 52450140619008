import * as actions from './graphics-client.actions';
import { GraphicsClient } from './graphics-client.model';

export interface GraphicsClientState {
  graphics: GraphicsClient
}

const initialState: GraphicsClientState = {
  graphics: null
}

export function graphicsClientReducer(state = initialState, action: actions.actions): GraphicsClientState {
  switch (action.type) {
    case actions.SET_GRAPHICS:
      return {
        ...state,
        graphics: action.graphics
      };

    case actions.UNSET_GRAPHICS:
      return {
        graphics: null
      };

    default:
      return state;
  }
}
