import { Action } from "@ngrx/store";
import { BarChartPlatform } from "./bar-chart-platform.model";


export const SET_BAR_CHART_PLATFORM = '[BarChartPlatform] Set BarChartPlatform';
export const UNSET_BAR_CHART_PLATFORM = '[BarChartPlatform] Unset BarChartPlatform';
export const UPDATE_BAR_CHART_PLATFORM = '[BarChartPlatform] Update BarChartPlatform';

export class SetBarChartPlatformAction implements Action {
  readonly type = SET_BAR_CHART_PLATFORM;

  constructor(public barChartPlatform: BarChartPlatform){}
}

export class UnsetBarChartPlatformAction implements Action { 
  readonly type = UNSET_BAR_CHART_PLATFORM;
}

export class UpdateBarChartPlatformAction implements Action {
  readonly type = UPDATE_BAR_CHART_PLATFORM;

  constructor(public barChartPlatform: BarChartPlatform){}
}

export type actions = SetBarChartPlatformAction | UnsetBarChartPlatformAction | UpdateBarChartPlatformAction;
