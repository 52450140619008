

export class Data {
  public accessToken: string;
  public datasetName: string;
  public breakdown: string;
  public date: string;
  public since: string;
  public until: string;
  public tableHead: string[];
  public moreThreeMonths: boolean;
  public graph: boolean;
  public graphDay: boolean;
  public showFB: boolean;
  public showTKTK: boolean;
  public showIconFB: boolean;
  public showIconTKTK: boolean;
  public isVitaldent: boolean;
  public isFbPrueba: boolean;
  public isBavieraItalia: boolean;
  public is1entre100mil: boolean;
  public isVirgin: boolean;
  public isKpi: boolean;
  public loadAll: boolean;
  public loadCampaigns: boolean;
  public loadDays: boolean;
  public loadPlatforms: boolean;
  public loadGroups: boolean;
  public comprobatedTKTK: boolean;
  public comprobatedFB: boolean;
  public accountsSelected: string[];
  public comparatorDataList: any[];
  public comparatorDataPlatformList: any[];
  public comparatorDateList: any[];

  constructor(obj?: IData) {
    this.accessToken = obj && obj.accessToken || '';
    this.datasetName = obj && obj.datasetName || 'facebook_prod';
    this.breakdown = obj && obj.breakdown || 'last_14d';
    this.date = obj && obj.date || '';
    this.since = obj && obj.since || '';
    this.until = obj && obj.until || '';
    this.moreThreeMonths = obj && obj.moreThreeMonths || false;
    this.graph = obj && obj.graph || false;
    this.graphDay = obj && obj.graphDay || false;
    this.showFB = obj && obj.showFB || false;
    this.showTKTK = obj && obj.showTKTK || false;
    this.showIconFB = obj && obj.showIconFB || false;
    this.showIconTKTK = obj && obj.showIconTKTK || false;
    this.isVitaldent = obj && obj.isVitaldent || false;
    this.isFbPrueba = obj && obj.isFbPrueba || false;
    this.isBavieraItalia = obj && obj.isBavieraItalia || false;
    this.is1entre100mil = obj && obj.is1entre100mil || false;
    this.isVirgin = obj && obj.isVirgin || false;
    this.isKpi = obj && obj.isKpi || false;
    this.tableHead = obj && obj.tableHead || [];
    this.loadAll = obj && obj.loadAll || false;
    this.loadCampaigns = obj && obj.loadCampaigns || false;
    this.loadDays = obj && obj.loadDays || false;
    this.loadPlatforms = obj && obj.loadPlatforms || false;
    this.loadGroups = obj && obj.loadGroups ||false;
    this.comprobatedFB = obj && obj.comprobatedFB || false;
    this.comprobatedTKTK = obj && obj.comprobatedTKTK || false;
    this.accountsSelected = obj && obj.accountsSelected || [];
    this.comparatorDataList = obj && obj.comparatorDataList || [];
    this.comparatorDataPlatformList = obj && obj.comparatorDataPlatformList || [];
    this.comparatorDateList = obj && obj.comparatorDateList || []
  }
}

export interface IData {
  accessToken: string;
  datasetName: string;
  breakdown: string;
  date: string;
  since: string;
  until: string;
  moreThreeMonths: boolean;
  graph: boolean;
  graphDay: boolean;
  showFB: boolean;
  showTKTK: boolean;
  showIconFB: boolean;
  showIconTKTK: boolean;
  tableHead: string[];
  isVitaldent: boolean;
  isFbPrueba: boolean;
  isBavieraItalia: boolean;
  is1entre100mil: boolean;
  isVirgin: boolean;
  isKpi: boolean;
  loadAll: boolean;
  loadCampaigns: boolean;
  loadDays: boolean;
  loadPlatforms: boolean;
  loadGroups: boolean;
  comprobatedTKTK: boolean;
  comprobatedFB: boolean;
  accountsSelected: string[];
  comparatorDataList: any[];
  comparatorDataPlatformList: any[];
  comparatorDateList: any[];
}
