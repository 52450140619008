import { Action } from "@ngrx/store";
import { GraphicImageAsCategory } from "./graphic-image-as-category.model";


export const SET_GRAPHICS_IMAGE_CATEGORY = '[Graphics Image Category] Set Graphics Image Category';
export const UNSET_GRAPHICS_IMAGE_CATEGORY = '[Graphics Image Category] Unset Graphics Image Category';

export class SetGraphicsImageAsCategoryAction implements Action {
  readonly type = SET_GRAPHICS_IMAGE_CATEGORY;

  constructor(public graphics: GraphicImageAsCategory){}
}

export class UnsetGraphicsImageAsCategoryAction implements Action {
  readonly type = UNSET_GRAPHICS_IMAGE_CATEGORY;
}

export class UpdateGraphicsImageAsCategoryAction implements Action {
  readonly type = SET_GRAPHICS_IMAGE_CATEGORY;

  constructor(public graphics: GraphicImageAsCategory){}
}

export type actions = SetGraphicsImageAsCategoryAction | UnsetGraphicsImageAsCategoryAction | UpdateGraphicsImageAsCategoryAction;
