import { Action } from "@ngrx/store";
import { PieChartCompany } from "./pie-chart-company.model";


export const SET_PIE_CHART_COMPANY = '[PieChartCompany] Set PieChartCompany';
export const UNSET_PIE_CHART_COMPANY = '[PieChartCompany] Unset PieChartCompany';
export const UPDATE_PIE_CHART_COMPANY = '[PieChartCompany] Update PieChartCompany';

export class SetPieChartCompanyAction implements Action {
  readonly type = SET_PIE_CHART_COMPANY;

  constructor(public pieChartCompany: PieChartCompany){}
}

export class UnsetPieChartCompanyAction implements Action { 
  readonly type = UNSET_PIE_CHART_COMPANY;
}

export class UpdatePieChartCompanyAction implements Action {
  readonly type = UPDATE_PIE_CHART_COMPANY;

  constructor(public pieChartCompany: PieChartCompany){}
}

export type actions = SetPieChartCompanyAction | UnsetPieChartCompanyAction | UpdatePieChartCompanyAction;
