import * as actions from './graphics-company.actions';
import { GraphicsCompany } from './graphics-company.model';

export interface GraphicsCompanyState {
  graphicsCompany: GraphicsCompany
}

const initialState: GraphicsCompanyState = {
  graphicsCompany: null
}

export function graphicsCompanyReducer(state = initialState, action: actions.actions): GraphicsCompanyState {
  switch (action.type) {
    case actions.SET_GRAPHICS_COMPANY:
      return {
        ...state,
        graphicsCompany: action.graphicsCompany
      };

    case actions.UNSET_GRAPHICS_COMPANY:
      return {
        graphicsCompany: null
      };

    default:
      return state;
  }
}
